import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { GiWallet } from "react-icons/gi";
import { BiCheckShield } from "react-icons/bi";
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Space from '~/components/space';

export default function AccountCard({ account }) {
  const { FillAccount, walletAccount } = useContext(WalletAccountContext);
  const { theme } = useContext(ThemeContext);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (walletAccount) {
      if (account.account_number === walletAccount.account_number && account.account_vd === walletAccount.account_vd && account.agency_number === walletAccount.agency_number) {
        return setIsActive(true);
      }
    }
    setIsActive(false);
  }, [walletAccount, account])

  return (
    <div onClick={() => FillAccount(account)}>
      <Card bodyStyle={{ padding: 10, cursor: isActive ? 'auto' : 'pointer', borderRadius: 8, border: `2px solid ${isActive ? theme.color.primary : theme.color.secondary}` }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GiWallet style={{ fontSize: '16pt', color: isActive ? theme.color.primary : 'auto' }} />
          <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
            <Space>
              <Text kind="secondary">Conta: </Text>
              <Text strong>{account.account_number}-{account.account_vd}</Text>
            </Space>
            <Space>
              <Text kind="secondary">Agência: </Text>
              <Text strong>{account.agency_number}</Text>
            </Space>
          </div>
        </div>
        {isActive &&
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <BiCheckShield style={{ fontSize: '8pt', color: theme.color.primary }} />
            <Text style={{ fontSize: '8pt', color: theme.color.primary }}>conta selecionada</Text>
          </div>
        }
      </Card>
    </div>
  );
}