import React from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
// Components
import Text from '~/components/typography/text';
import WalletAccountAuth from '~/pages/PaymentGateway/methods/components/WalletAccountAuth';
//import Button from '~/components/button';
// Own Components
import UserContainer from './components/UserContainer';
import Balance from './components/Balance';
// Pages
import UserAuthentication from './pages/UserAuthentication';
// Utils
import { ExistingUser } from '~/utils/UserInformation';

export default function SocialMeAccounts() {
  if (!ExistingUser()) {
    return <UserAuthentication />;
  }

  return (
    <Animate Animation={FadeIn} duration={['0.5s']} delay={['0s']}>
      <Text strong style={{ marginBottom: '10px' }}>
        Conta
      </Text>
      <UserContainer />
      <Text strong style={{ marginBottom: '10px' }}>
        Selecione a conta onde será adicionado os Me's
      </Text>
      <WalletAccountAuth />
      <Text strong style={{ marginBottom: '10px' }}>
        Saldo da conta selecionada
      </Text>
      <Balance />
    </Animate>
  );
}
