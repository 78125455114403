import React, { useContext, useEffect } from 'react';
//Libraries
import { Form, message } from 'antd';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Button from '~/components/button';
import InputNumber from '~/components/inputNumber';
import Space from '~/components/space';
import Text from '~/components/typography/text';
import FormItem from '~/components/form/FormItem';
// Environments
import System from '~/environments/System';
// Utils
import { WalletFormatParser } from '~/utils/Parsers';
import { WalletFormatConversion } from '~/utils/Transformations';

export default function ModalContent({ CloseModal, parameters }) {
  const { intent, setIntent } = useContext(GatewayContext);
  const [form] = Form.useForm();
  async function UpdateValue(newValue) {
    setIntent({ ...intent, value: newValue });
    message.success('Valor autalizado');
    CloseModal();
  }
  async function onFinish(data) {
    if (intent.agent === System.gateway.agents.donationProductService) {
      if (WalletFormatParser(data.newValue) < intent.value) {
        console.log('error');
        return message.error(
          'O novo valor não pode ser menor que o valor solicitado na doação'
        );
      }
    }
    UpdateValue(WalletFormatParser(data.newValue));
  }
  useEffect(() => {
    //form.setFieldsValue().newValue = 20;
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Space direction="vertical" align="center">
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
          }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            newValue: 1,
          }}
        >
          <Text strong style={{ textAlign: 'center' }}>
            Insira um novo valor
          </Text>
          <FormItem
            style={{ margin: '0px 0px 10px' }}
            name="newValue"
            rules={[
              {
                required: true,
                message: 'Um novo valor é obrigatório',
              },
            ]}
            item={
              <InputNumber
                min={WalletFormatConversion(intent.value)}
                style={{ width: 200 }}
              />
            }
          />

          <Button
            size="large"
            type="primary"
            style={{ width: 200 }}
            htmlType="submit"
          >
            Atualizar valor
          </Button>
        </Form>
      </Space>
    </div>
  );
}
