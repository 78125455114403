import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Own Components
import Checkout from './components/Checkout';
// Components
import Button from '~/components/button';
import Modal from '~/components/modal';

export default function ConfirmButton() {
  const {
    activeMethod,
    amount,
    accountBalance,
    formValues,
    intent,
  } = useContext(GatewayContext);
  const { walletAccount } = useContext(WalletAccountContext);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    async function Validator() {
      //console.log('formValues -> ', formValues);
      if (formValues) {
        if (
          activeMethod === 'DEBIT' ||
          activeMethod === 'CREDIT' ||
          activeMethod === 'ME-CC'
        ) {
          if (formValues.card) {
            if (!formValues.card.number) return false;
            if (!formValues.card.expirationDate) return false;
            if (!formValues.card.code) return false;
            if (!formValues.card.holder) return false;
          }
          if (formValues.payer) {
            if (!formValues.payer.firstName) return false;
            if (!formValues.payer.lastName) return false;
            if (!formValues.payer.mainDocument) return false;
          }
        }
        return true;
      }
      return false;
    }
    async function VerifyMethod() {
      const validator = await Validator();
      if (validator) {
        setDisabledButton(false);
      }
    }
    if (activeMethod) {
      setDisabledButton(true);
      VerifyMethod();
    }
  }, [accountBalance, activeMethod, amount, walletAccount, formValues, intent]);

  if (!disabledButton) {
    return (
      <Modal
        Trigger={
          <Button
            disabled={disabledButton}
            loading={false}
            type="primary"
            style={{
              marginTop: 20,
              width: '100%',
              height: 80,
              fontSize: '16pt',
            }}
          >
            Confirmar
          </Button>
        }
        InheritedContent={Checkout}
        footer={false}
      />
    );
  }

  return (
    <div>
      <Button
        disabled={disabledButton}
        loading={false}
        type="primary"
        style={{ marginTop: 20, width: '100%', height: 80, fontSize: '16pt' }}
      >
        Confirmar
      </Button>
    </div>
  );
}
