import React, { useContext } from 'react';
// Libraries
import { Row, Col } from 'antd';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Select from '~/components/select';

export default function CardForm({ setFlip }) {
  const { activeMethod } = useContext(GatewayContext);
  const flagOptionsDEBIT = [
    {
      value: 'Visa',
      label: 'Visa',
    },
    {
      value: 'Master',
      label: 'Master',
    },
    {
      value: 'Amex',
      label: 'Amex',
    },
    {
      value: 'Diners',
      label: 'Diners',
    },
    {
      value: 'Discover',
      label: 'Discover',
    },
    {
      value: 'JCB',
      label: 'JCB',
    },
  ];
  const flagOptionsCREDIT = [
    {
      value: 'Visa',
      label: 'Visa',
    },
    {
      value: 'Master',
      label: 'Master',
    },
    {
      value: 'Elo',
      label: 'Elo',
    },
    {
      value: 'Amex',
      label: 'Amex',
    },
    {
      value: 'Diners',
      label: 'Diners',
    },
    {
      value: 'Discover',
      label: 'Discover',
    },
    {
      value: 'JCB',
      label: 'JCB',
    },
  ];
  return (
    <div style={{ padding: 10 }}>
      <Row>
        <Col span={12} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Número do Cartão"
            name={['card', 'number']}
            item={<Input mask="1111 1111 1111 1111" />}
          />
        </Col>
        <Col span={12} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Bandeira"
            name={['card', 'brand']}
            item={<Select options={activeMethod === 'DEBIT' ? flagOptionsDEBIT : flagOptionsCREDIT} noDefaultValue />}
          />
        </Col>
        <Col span={12} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Validade"
            name={['card', 'expirationDate']}
            item={<Input mask="11/11" />}
          />
        </Col>
        <Col span={12} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Código de Segurança"
            name={['card', 'code']}
            item={
              <Input
                onFocus={() => setFlip(true)}
                onBlur={() => setFlip(false)}
                maxLength={4}
              />
            }
          />
        </Col>
        <Col span={24} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Títular do cartão"
            name={['card', 'holder']}
            item={<Input />}
          />
        </Col>
        {/* 
        <Col span={24} style={{ padding: '0px 5px' }}>
          <FormItem
            label="CPF"
            name={['cpf']}
            item={<Input mask="111.111.111-11" />}
          />
        </Col>
        <Col span={12} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Telefone"
            name={['phone']}
            item={<Input mask="(11) 91111-1111" />}
          />
        </Col>
        <Col span={12} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Data de Nascimento"
            name={['birth']}
            item={<Input mask="11/11/1111" />}
          />
        </Col>
        */}
      </Row>
    </div>
  );
}
