import React from 'react';
// Components
import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function DonationProductService({ intent }) {
  return (
    <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
      <Text strong style={{ textAlign: 'center' }}>
        Doação
      </Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 5,
          }}
        >
          <Avatar src={intent.dr_person_avatar} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Text style={{ fontSize: '10pt' }} strong>
            {CapitalizeString(
              intent.dr_person_social_name || intent.dr_person_name
            )}
          </Text>
          <Text
            style={{ fontSize: '8pt' }}
            kind="secondary"
          >{`${CapitalizeString(intent.dr_ps_name)}`}</Text>
        </div>
      </div>
    </div>
  );
}
