import React from 'react';
// Components
import Space from '~/components/space';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function PartnerProductService({ intent }) {
  function GetKind() {
    if (intent.ps_kind === 'PRODUCT') return 'Produto';
    if (intent.ps_kind === 'SERVICE') return 'Serviço';
  }
  return (
    <div style={{ padding: 10 }}>
      <Space direction="vertical">
        <div>
          <Text type="secondary" fontSize="10pt">
            {GetKind()}
          </Text>
          <Text strong fontSize="14pt">
            {CapitalizeString(intent.ps_name)}
          </Text>
        </div>
      </Space>
    </div>
  );
}
