import React, { useContext, useState } from 'react';
// Libraries
import { message } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import Button from '~/components/button';
import Card from '~/components/card';
import Input from '~/components/input';
import Space from '~/components/space';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
// Utils
import { WalletFormatConversion } from '~/utils/Transformations';
// Environments
import System from '~/environments/System';
// Methods
import METHODS from '~/pages/PaymentGateway/methods';

export default function Checkout() {
  const {
    activeMethod,
    ConfirmOperation,
    amount,
    formValues,
    files,
    intent,
  } = useContext(GatewayContext);
  const { walletAccountNumber, setWalletPassword } = useContext(
    WalletAccountContext
  );
  const [loadingButton, setLoadingButton] = useState(false);

  async function Confirm() {
    setLoadingButton(true);
    let possibleFields = [
      'description',
      'fullName',
      'mainDocument',
      'bank',
      'agency',
      'accountNumber',
    ];
    let data = {
      settings: {},
    };
    if (files) {
      data.settings.files = files;
    }
    if (formValues && formValues.settings) {
      possibleFields.map((field) => {
        if (formValues.settings[field]) {
          return (data.settings[field] = formValues.settings[field]);
        }
        return null;
      });
    }
    const response = await ConfirmOperation(data);
    console.log(response);
    if (
      response.message ===
      "The parameter 'account or password' that was sent is invalid"
    ) {
      message.error('Senha incorreta');
    }
    setLoadingButton(false);
  }
  return (
    <div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Revisão
      </Title>
      <Space direction="vertical">
        <Space>
          <Text strong>Operação:</Text>
          <Text>
            {intent.agent === System.gateway.agents.personManualCashIn
              ? "Entrada de Me's"
              : "Saída de Me's"}
          </Text>
        </Space>
        <Space>
          <Text strong>Método:</Text>
          <Text>{METHODS[activeMethod].text}</Text>
        </Space>
        <Space>
          <Text strong>Valor:</Text>
          <Text>{WalletFormatConversion(amount)}</Text>
        </Space>
        <Space>
          <Text strong>Conta selecionada:</Text>
          <Text>{walletAccountNumber}</Text>
        </Space>
        <Space>
          <Text strong>Arquivos enviados:</Text>
          {files.map((file, index) => (
            <Card
              key={file}
              bodyStyle={{ padding: 5, display: 'flex', alignItems: 'center' }}
            >
              <Text>Arquivo {index + 1}</Text>
              <a href={file} target="_blank" rel="noopener noreferrer">
                <EyeOutlined style={{ marginLeft: 10, cursor: 'pointer' }} />
              </a>
            </Card>
          ))}
        </Space>
        {formValues && formValues.settings && formValues.settings.billetNumber && (
          <Space>
            <Text strong>Número do boleto:</Text>
            <Text>{formValues.settings.billetNumber}</Text>
          </Space>
        )}
        {formValues && formValues.settings && formValues.settings.description && (
          <Space>
            <Text strong>Descrição:</Text>
            <Text>{formValues.settings.description}</Text>
          </Space>
        )}
        <Space>
          <Input
            label="Senha da conta"
            type="password"
            maxLength={4}
            onChange={(event) => setWalletPassword(event.target.value)}
          />
        </Space>
        <Space>
          <Button
            type="primary"
            size="large"
            loading={loadingButton}
            onClick={() => Confirm()}
          >
            Confirmar operação
          </Button>
        </Space>
      </Space>
    </div>
  );
}
