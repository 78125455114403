import React from 'react';
// Components
import Text from '~/components/typography/text';

export default function Partner({ intent }) {
  return (
    <div style={{ padding: 10 }}>
      <Text strong style={{ textAlign: 'center' }}>
        Parceiro
      </Text>
    </div>
  );
}
