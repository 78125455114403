import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
// Environments
import { Banks } from '~/environments/Selects';

export default function FormItemBank() {
  return (
    <FormItem
      name={['settings', 'bank']}
      item={<Select label={'Banco'} options={Banks} noDefaultValue />}
    />
  );
}
