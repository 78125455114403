import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { GatewayContext } from '~/contexts/GatewayContext';
// Own Components
import Header from '~/pages/PaymentGateway/pages/Home/components/Header';
import OrderSummary from '~/pages/PaymentGateway/pages/Home/components/OrderSummary';
import Flow from '~/pages/PaymentGateway/pages/Home/components/Flow';
import LoadingGateway from '~/pages/PaymentGateway/components/LoadingGateway';
// Environments
import UrlParameters from '~/environments/UrlParameters';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Pages
import InvalidPage from '~/pages/PaymentGateway/pages/Home/components/ResponsePages/InvalidOperation';
import CompletedPage from '~/pages/PaymentGateway/pages/Home/components/ResponsePages/CompletedOperation';
import PendingPage from '~/pages/PaymentGateway/pages/Home/components/ResponsePages/PendingOperation';
import ErrorPage from '~/pages/PaymentGateway/pages/Home/components/ResponsePages/ErrorOperation';

export default function Home() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);
  const {
    intent,
    setGateways,
    setQRCodeInformation,
    setIntent,
    completedOperation,
    operationResponse,
  } = useContext(GatewayContext);
  const [intentId, setIntentId] = useState();
  const [intentInvalid, setIntentInvalid] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    async function GetIntent() {
      const params = {
        operation: ['Gateway', 'GetIntent'],
        urlParams: {
          intentId: intentId,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setGateways(response.body.gateway);
        if (response.body.intent.other_parameters.QRCodeInformation) {
          setQRCodeInformation(
            response.body.intent.other_parameters.QRCodeInformation
          );
        }
        setIntent(response.body.intent);
      } else {
        setIntentInvalid(true);
      }
      return setLoadingContent(false);
    }
    if (intentId) {
      GetIntent();
    }
  }, [intentId, setGateways, setIntent, setQRCodeInformation]);

  useEffect(() => {
    async function PreparesParameters() {
      let piece1 = query.get(UrlParameters.gatewayPiece1);
      let piece2 = query.get(UrlParameters.gatewayPiece2);
      let piece3 = query.get(UrlParameters.gatewayPiece3);
      //console.log('piece 1 -> ' + piece1);
      //console.log('piece 2 -> ' + piece2);
      //console.log('piece 3 -> ' + piece3);
      if (piece1 && piece2 && piece3) {
        piece1 = piece1.replaceAll(' ', '+');
        piece2 = piece2.replaceAll(' ', '+');
        piece3 = piece3.replaceAll(' ', '+');
        if (
          piece1.length === 10 &&
          piece2.length === 11 &&
          piece3.length === 11
        ) {
          return setIntentId(`${piece1}${piece2}${piece3}`);
        }
      }
      setIntentInvalid(true);
      setLoadingContent(false);
    }
    PreparesParameters();
  }, [query]);

  if (loadingContent) {
    return <LoadingGateway />;
  }

  if (intentInvalid) {
    return <InvalidPage />;
  }

  if (completedOperation) {
    console.log(completedOperation);
    console.log(operationResponse);
    if (operationResponse.status === 'ACTIVE') {
      return (
        <CompletedPage operationResponse={operationResponse} intent={intent} />
      );
    }
    if (operationResponse.status === 'INACTIVE') {
      return <ErrorPage />;
    }
    if (operationResponse.status === 'PENDING') {
      return <PendingPage operationResponse={operationResponse} />;
    }
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Header />
        </Col>
      </Row>
      <Row
        style={{
          padding: isMobile ? '0px 10px' : '0px 50px',
          backgroundColor: theme.color.backgroundSecondary,
        }}
      >
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <Flow />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <OrderSummary />
        </Col>
      </Row>
    </div>
  );
}
