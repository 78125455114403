import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Own Components
import Checkout from './components/Checkout';
// Components
import Button from '~/components/button';
import Modal from '~/components/modal';
// Environments
import System from '~/environments/System';

export default function ConfirmButton() {
  const { activeMethod, amount, accountBalance, formValues, intent } = useContext(
    GatewayContext
  );
  const { walletAccount } = useContext(WalletAccountContext);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    async function Validator() {
      if(!walletAccount) return false;
      if(formValues && formValues.settings){
        if(!formValues.settings.fullName) return false;
        if(!formValues.settings.mainDocument) return false;
        if(!formValues.settings.bank) return false;
        if(!formValues.settings.agency) return false;
        if(!formValues.settings.accountNumber) return false;
        return true;
      }
     return false;
    }
    async function VerifyMethod() {
      const validator = await Validator();
      if (validator) {
        console.log('DOCMANUAL validator -> ' + validator);
        setDisabledButton(false);
      }
    }
    if (activeMethod) {
      setDisabledButton(true);
      VerifyMethod();
    }
  }, [accountBalance, activeMethod, amount, walletAccount, formValues]);

  if (!disabledButton) {
    return (
      <Modal
        Trigger={
          <Button
            disabled={disabledButton}
            loading={false}
            type="primary"
            style={{ marginTop: 20, width: '100%', height: 80, fontSize: '16pt' }}
          >
            {intent.agent === System.gateway.agents.personManualCashIn ? "Entrada de Me's" : "Saída de Me's"}
          </Button>
        }
        InheritedContent={Checkout}
        footer={false}
      />
    )
  }

  return (
    <div>
      <Button
        disabled={disabledButton}
        loading={false}
        type="primary"
        style={{ marginTop: 20, width: '100%', height: 80, fontSize: '16pt' }}
      >
        {intent.agent === System.gateway.agents.personManualCashIn ? "Entrada de Me's" : "Saída de Me's"}
      </Button>
    </div>
  );
}
