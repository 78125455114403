import React from 'react';
// Components
import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';
// Utils
import {CapitalizeString} from '~/utils/Transformations';

export default function PersonManualCashIn({ intent }) {
  return (
    <div style={{ padding: 10 }}>
      <Text strong style={{ textAlign: 'center' }}>
        Entrada de Me's
      </Text>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar style={{ marginRight: 5 }} src={intent.p_person_avatar} />
        <Text>{CapitalizeString(intent.p_person_social_name || intent.p_person_name)}</Text>
      </div>
    </div>
  );
}
