import React, { useEffect, useContext, useState } from 'react';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
// Utils
import { WalletFormatConversion } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Balance() {
    const { walletAccountNumber } = useContext(WalletAccountContext);
    const { theme } = useContext(ThemeContext);
    const { amount } = useContext(GatewayContext);

    const [balance, setBalance] = useState(0);
    const [loadingBalance, setLoadingBalance] = useState(true);

    useEffect(() => {
        async function GetBalance() {
            const params = {
                operation: ['Wallet', 'Accounts', 'Balance'],
                urlParams: {
                    accountNumber: walletAccountNumber,
                },
            };
            const response = await SocialMeAPI(params);
            console.log('Saldo -> ', response.body.balance);
            if (response.success) {
                setBalance(response.body.balance);
                return setLoadingBalance(false);
            }
            return setLoadingBalance(false);
        }
        GetBalance();
    }, [walletAccountNumber]);

    function VerifyBalance() {
        const realBalance = balance.SME.CC;
        const orderValue = amount;
        if (realBalance > orderValue) {
            return (
                <Text color={theme.color.success} textAlign="center">
                    Saldo disponível para o pagamento
                </Text>
            );
        } else {
            return (
                <Text color={theme.color.danger} textAlign="center">
                    Saldo indisponível para o pagamento
                </Text>
            );
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}
        >
            {loadingBalance ? (
                <Loading text="Carregando saldo" />
            ) : (
                    <div>
                        <div style={{ display: 'flex' }}>
                            <Text style={{ marginRight: 5 }}>Saldo disponível:</Text>
                            <Text strong>
                                {WalletFormatConversion(balance.SME.CC)}
                            </Text>
                        </div>
                        {VerifyBalance()}
                    </div>
                )}
        </div>
    );
}
