import React, { useState, useContext, useEffect } from 'react';
// Libraries
import { Form, Divider, Row, Col } from 'antd';
// Context
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Title from '~/components/typography/title';
// Own Components
import CardForm from '../../components/CardForm';
import Card from '../../components/Card';
import PersonForm from '../../components/PersonForm';

export default function DebitCard() {
  const { isMobile } = useContext(ResponsiveContext);
  const { formValues, setFormValues } = useContext(GatewayContext);
  const [flip, setFlip] = useState(false);
  const [flag, setFlag] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (flag) {
      if (formValues.flag !== flag) {
        setFormValues({
          ...formValues,
          flag: flag,
        });
      }
      return;
    }
  }, [formValues, setFormValues, flag]);

  if (isMobile) {
    return (
      <div style={{ padding: 10 }}>
        <Row>
          <Col span={24}>
            <Title level={4}>Dados do titular do cartão</Title>
            <Divider style={{ margin: '10px 0px' }} />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              number={
                formValues && formValues.card && formValues.card.number
                  ? formValues.card.number
                  : ''
              }
              validity={
                formValues && formValues.card && formValues.card.expirationDate
                  ? formValues.card.expirationDate
                  : ''
              }
              name={
                formValues && formValues.card && formValues.card.holder
                  ? formValues.card.holder
                  : ''
              }
              cvv={
                formValues && formValues.card && formValues.card.code
                  ? formValues.card.code
                  : ''
              }
              showBack={flip}
              flag={form.getFieldValue(['card', 'brand'])}
              setFlag={setFlag}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form
              labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
              form={form}
              size="small"
              layout="vertical"
              onValuesChange={() => setFormValues(form.getFieldsValue())}
              scrollToFirstError
            >
              <CardForm setFlip={setFlip} />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div style={{ padding: 10 }}>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        onValuesChange={() => setFormValues(form.getFieldsValue())}
        scrollToFirstError
      >
        <Row>
          <Col span={24}>
            <Title level={4}>Dados do titular do cartão</Title>
            <Divider style={{ margin: '10px 0px' }} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <CardForm setFlip={setFlip} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              number={
                formValues && formValues.card && formValues.card.number
                  ? formValues.card.number
                  : ''
              }
              validity={
                formValues && formValues.card && formValues.card.expirationDate
                  ? formValues.card.expirationDate
                  : ''
              }
              name={
                formValues && formValues.card && formValues.card.holder
                  ? formValues.card.holder
                  : ''
              }
              cvv={
                formValues && formValues.card && formValues.card.code
                  ? formValues.card.code
                  : ''
              }
              showBack={flip}
              flag={form.getFieldValue(['card', 'brand'])}
              setFlag={setFlag}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Dados do pagador</Title>
            <Divider style={{ margin: '10px 0px' }} />
          </Col>
        </Row>
        <PersonForm />
      </Form>
    </div>
  );
}
