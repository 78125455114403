import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 320px;
  height: 200px;
`;

export const Flip = styled.div`
  width: inherit;
  height: inherit;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: ${(props) =>
    props.showBack ? 'rotateY(180deg)' : '' || ''};
  transform: ${(props) => (props.showBack ? 'rotateY(180deg)' : '' || '')};
  //-webkit-transform: rotateY(180deg);
  //transform: rotateY(180deg);
`;

export const Front = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* background-image: -webkit-gradient(linear, left top, right top, from(#111), to(#555)); */
  /* background-image: linear-gradient(to right, #111, #555); */
  background-color: ${(props) =>
    props.flagColor
      ? props.flagColor
      : 'rgba(0, 0, 0, 0.3)' || 'rgba(0, 0, 0, 0.3)'};
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 0.5s;
`;

export const Logo = styled.div`
  position: absolute;
  top: 30px;
  right: 25px;
`;

export const Chip = styled.div`
  position: relative;
  top: 40px;
  left: 25px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    right top,
    left bottom,
    from(#ffecc7),
    to(#d0b978)
  );
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
  overflow: hidden;
`;

export const ChipLine = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #333;
  &:nth-child(1) {
    top: 13px;
  }
  &:nth-child(2) {
    top: 20px;
  }
  &:nth-child(3) {
    top: 28px;
  }
  &:nth-child(4) {
    left: 25px;
    width: 1px;
    height: 50px;
  }
`;

export const ChipMain = styled.div`
  width: 20px;
  height: 25px;
  border: 1px solid #333;
  border-radius: 3px;
  background-image: -webkit-gradient(
    linear,
    right top,
    left bottom,
    from(#efdbab),
    to(#e1cb94)
  );
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;
`;

export const CardNumber = styled.div`
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin: 10px 25px 10px;
  font-size: 23px;
  font-family: 'cc font', monospace;
`;

export const ValidityContainer = styled.div`
  margin-left: 25px;
  text-transform: uppercase;
  font-family: 'cc font', monospace;
`;

export const ValidityText = styled.span`
  font-size: 9px;
  color: rgba(255, 255, 255, 0.8);
`;

export const ValidityDate = styled.span``;

export const CardholderName = styled.div`
  margin: 10px 25px;
  text-transform: uppercase;
  font-family: 'cc font', monospace;
`;

export const Back = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* background-image: -webkit-gradient(linear, left top, right top, from(#111), to(#555)); */
  /* background-image: linear-gradient(to right, #111, #555); */
  background-color: red;
  overflow: hidden;
  -webkit-transform: rotateY(180deg) translateZ(0);
  transform: rotateY(180deg) translateZ(0);
  background: #9e9e9e;
`;

export const StripBlack = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 50px;
  background: #000;
`;

export const CVVContainer = styled.div`
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  height: 36px;
  width: 90%;
  padding: 10px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: right;
  letter-spacing: 1px;
  color: #000;
  background: #fff;
`;

export const CVVLabel = styled.span`
  display: block;
  margin: -30px 0 15px;
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
`;

export const CVVValue = styled.div``;
