import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import Checkout from './components/Checkout';
// Components
import Button from '~/components/button';
import Modal from '~/components/modal';
import Text from '~/components/typography/text';

export default function ConfirmButton() {
  const {
    activeMethod,
    amount,
    accountBalance,
    formValues,
    intent,
    QRCodeInformation,
  } = useContext(GatewayContext);
  const { walletAccount } = useContext(WalletAccountContext);
  const { theme } = useContext(ThemeContext);
  const [disabledButton, setDisabledButton] = useState(true);
  const [feedbackMessage, setFeedbackMessage] = useState();

  useEffect(() => {
    async function Validator() {
      if (amount > QRCodeInformation.amount.SME) {
        setFeedbackMessage('Valor do pedido maior que o valor solicitado');
        return false;
      }
      if (intent.psm_id !== QRCodeInformation.psd_model_id) {
        setFeedbackMessage(
          'Produto do pedido é diferente do produto solicitado'
        );
        return false;
      }
      return true;
    }
    async function VerifyMethod() {
      const validator = await Validator();
      if (validator) {
        setDisabledButton(false);
      }
    }
    if (activeMethod && QRCodeInformation) {
      setDisabledButton(true);
      VerifyMethod();
    }
  }, [
    accountBalance,
    activeMethod,
    amount,
    walletAccount,
    formValues,
    intent,
    QRCodeInformation,
  ]);

  if (!disabledButton) {
    return (
      <Modal
        Trigger={
          <Button
            disabled={disabledButton}
            loading={false}
            type="primary"
            style={{
              marginTop: 20,
              width: '100%',
              height: 80,
              fontSize: '16pt',
            }}
          >
            Confirmar venda
          </Button>
        }
        InheritedContent={Checkout}
        footer={false}
      />
    );
  }

  return (
    <div>
      <Button
        disabled={disabledButton}
        loading={false}
        type="primary"
        style={{ marginTop: 20, width: '100%', height: 80, fontSize: '16pt' }}
      >
        Confirmar venda
      </Button>
      {feedbackMessage && (
        <Text style={{ textAlign: 'center', color: theme.color.danger }}>
          {feedbackMessage}
        </Text>
      )}
    </div>
  );
}
