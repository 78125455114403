import styled from 'styled-components';

import { BsFillTrashFill, BsEye } from 'react-icons/bs';

export const UploadContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Label = styled.label`
  box-sizing: border-box !important;
  background-image: url(${(props) => props.preview});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.backgroundColor};
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  padding: 12px 4px;
  min-width: ${(props) => `${props.width}px`};
  min-height: ${(props) => `${props.height}px`};

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  border: 2px solid ${(props) => props.borderColor};

  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.hoverColor};
    color: ${(props) => props.hoverColor};
  }

  @media (max-width: 768px) {
    padding: 5px;
    margin: 0;
  }
`;

export const Input = styled.input`
  display: none !important;
`;

export const Column = styled.input`
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  position: relative;
  cursor: pointer;

  &:hover svg {
    display: block;
  }

  &::after {
    content: '';
    transition: all 0.4s;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0);

    top: 0;
    left: 0;
    position: absolute;
  }

  &:hover:after {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const Delete = styled(BsFillTrashFill)`
  position: absolute;
  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 22px;
  top: 40px;
  left: 51px;
  z-index: 1;
  display: none;
  transition: all 0.4s;

  &:hover {
    color: ${(props) => `${props.hovercolor} !important`};
  }
`;

export const View = styled(BsEye)`
  position: absolute;
  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 22px;
  top: 40px;
  left: 29px;
  z-index: 1;
  display: none;
  transition: all 0.4s;

  &:hover {
    color: ${(props) => `${props.hovercolor} !important`};
  }
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  object-fit: cover;
  height: 100px;
  width: 100px;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fafafa;
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;
`;

export const Link = styled.a`
`