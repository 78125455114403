import React from 'react';
// Libraries
import { Result } from 'antd';
// Components
import Button from '~/components/button';

export default function IvanlidOperation({ operationResponse }) {
  return (
    <div>
      <Result
        status="error"
        title="Operação inválida"
        subTitle={`Ocorreu um erro durante a operação`}
        extra={[
          <Button
            type="primary"
            key={1}
            onClick={() =>
              window.location.replace(
                //`https://${System.hostname.operationsFinder}`
                `${operationResponse.other_parameters.origin_url}`
              )
            }
          >
            Me tire daqui
          </Button>,
        ]}
      />
    </div>
  );
}
