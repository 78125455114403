import React, { useContext, useState } from 'react';
// Libraries
import { message } from 'antd';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import Button from '~/components/button';
import Input from '~/components/input';
import Space from '~/components/space';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
// Utils
import { WalletFormatConversion } from '~/utils/Transformations';
// Methods
import METHODS from './../../../../../methods';

export default function Checkout() {
  const {
    activeMethod,
    ConfirmOperation,
    amount,
  } = useContext(GatewayContext);
  const { walletAccountNumber, setWalletPassword } = useContext(
    WalletAccountContext
  );
  const [loadingButton, setLoadingButton] = useState(false);

  async function Confirm() {
    setLoadingButton(true);
    let data = {
      settings: {},
    };
    const response = await ConfirmOperation(data);
    console.log(response);
    if (
      response.message ===
      "The parameter 'account or password' that was sent is invalid"
    ) {
      message.error('Senha incorreta');
    }
    setLoadingButton(false);
  }
  return (
    <div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Revisão
      </Title>
      <Space direction="vertical">
        <Space>
          <Text strong>Método:</Text>
          <Text>{METHODS[activeMethod].text}</Text>
        </Space>
        <Space>
          <Text strong>Valor:</Text>
          <Text>{WalletFormatConversion(amount)}</Text>
        </Space>
        <Space>
          <Text strong>Conta selecionada:</Text>
          <Text>{walletAccountNumber}</Text>
        </Space>
        <Space>
          <Input
            label="Senha da conta"
            type="password"
            maxLength={4}
            onChange={(event) => setWalletPassword(event.target.value)}
          />
        </Space>
        <Space>
          <Button
            type="primary"
            size="large"
            loading={loadingButton}
            onClick={() => Confirm()}
          >
            Confirmar operação
          </Button>
        </Space>
      </Space>
    </div>
  );
}
