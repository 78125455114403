import React, { useState, useEffect } from 'react';
//Libraries
import { useLocation } from 'react-router-dom';
// Components
import LoadingGateway from '~/components/loading/loadingGateway';
// Environments
import UrlParameters from '~/environments/UrlParameters';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Pages
import InvalidPage from '~/pages/PaymentGateway/pages/Verify/components/ResponsePages/InvalidOperation';
import CompletedPage from '~/pages/PaymentGateway/pages/Verify/components/ResponsePages/CompletedOperation';
import PendingPage from '~/pages/PaymentGateway/pages/Verify/components/ResponsePages/PendingOperation';
import ErrorOperation from '~/pages/PaymentGateway/pages/Verify/components/ResponsePages/ErrorOperation';

export default function Verify() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [operationInvalid, setOperationInvalid] = useState(false);
  const [operationId, setOperationId] = useState();
  const [operation, setOperation] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  async function GetOperationAgain() {
    setLoadingContent(true);
    const params = {
      operation: ['Gateway', 'GetOperation'],
      urlParams: {
        operationId: operationId,
      },
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      setOperation(response.body.operation[0]);
    }
    setLoadingContent(false);
  }

  useEffect(() => {
    async function GetOperation() {
      const params = {
        operation: ['Gateway', 'GetOperation'],
        urlParams: {
          operationId: operationId,
        },
      };
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        setOperation(response.body.operation[0]);
      }
      setLoadingContent(false);
    }
    if (operationId) {
      GetOperation();
    }
  }, [operationId]);

  useEffect(() => {
    async function PreparesParameters() {
      let piece1 = query.get(UrlParameters.gatewayPiece1);
      let piece2 = query.get(UrlParameters.gatewayPiece2);
      let piece3 = query.get(UrlParameters.gatewayPiece3);
      if (piece1 && piece2 && piece3) {
        piece1 = piece1.replaceAll(' ', '+');
        piece2 = piece2.replaceAll(' ', '+');
        piece3 = piece3.replaceAll(' ', '+');
        if (
          piece1.length === 11 &&
          piece2.length === 11 &&
          piece3.length === 11
        ) {
          return setOperationId(`${piece1}${piece2}${piece3}`);
        }
      }
      setOperationInvalid(true);
    }
    PreparesParameters();
  }, [query]);

  if (operationInvalid) {
    return <InvalidPage />;
  }

  if (loadingContent) {
    return <LoadingGateway />;
  }

  if (operation) {
    if (operation.status === 'ACTIVE') {
      return <CompletedPage operationResponse={operation} />;
    }
    if (operation.status === 'INACTIVE') {
      return <ErrorOperation />;
    }
    if (operation.status === 'PENDING') {
      return (
        <PendingPage
          operationResponse={operation}
          GetOperationAgain={GetOperationAgain}
        />
      );
    }
  }
}
