import React, { useContext, useState, useEffect } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Own Components
import Checkout from './components/Checkout';
// Components
import Button from '~/components/button';
import Modal from '~/components/modal';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ConfirmButton() {
  const { activeMethod, amount, formValues, intent } = useContext(
    GatewayContext
  );
  const { walletAccount, walletAccountNumber } = useContext(WalletAccountContext);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    async function GetBalance() {
      const params = {
        operation: ['Wallet', 'Accounts', 'Balance'],
        urlParams: {
          accountNumber: walletAccountNumber
        },
      };
      const response = await SocialMeAPI(params);
      return response.body.balance.SME.CC;
    }
    async function Validator() {
      const balance = await GetBalance();
      if (!walletAccount) return false;
      if(balance >= amount){
        return true
      }
      return false;
    }
    async function VerifyMethod() {
      const validator = await Validator();
      if (validator) {
        console.log('Validator -> ' + validator);
        setDisabledButton(false);
      }
    }
    if (activeMethod) {
      setDisabledButton(true);
      if(walletAccount){
        VerifyMethod();
      }
    }
  }, [activeMethod, amount, walletAccount, formValues, intent, walletAccountNumber]);

  if (!disabledButton) {
    return (
      <Modal
        Trigger={
          <Button
            disabled={disabledButton}
            loading={false}
            type="primary"
            style={{ marginTop: 20, width: '100%', height: 80, fontSize: '16pt' }}
          >
             Confirmar
          </Button>
        }
        InheritedContent={Checkout}
        footer={false}
      />
    )
  }

  return (
    <div>
      <Button
        disabled={disabledButton}
        loading={false}
        type="primary"
        style={{ marginTop: 20, width: '100%', height: 80, fontSize: '16pt' }}
      >
         Confirmar
      </Button>
    </div>
  );
}
