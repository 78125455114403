import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import TextArea from '~/components/textArea';

export default function FormItemDescription() {
  return (
    <FormItem
      name={['settings', 'description']}
      item={<TextArea label="Descrição" outFormItem />}
    />
  );
}
