import React, { useContext, useEffect, useState } from 'react';
// Libraries
import { Row, Col } from 'antd';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Own Components
import MethodCard from './components/MethodCard';
// Components
import Text from '~/components/typography/text';
// Methods
import METHODS from '~/pages/PaymentGateway/methods';

export default function MethodSelection() {
  const { gateways } = useContext(GatewayContext);
  const [methods, SetMethods] = useState([]);

  useEffect(() => {
    async function PrepareMethods() {
      let activeMethods = [];
      for (const keyGateway in gateways) {
        if (Object.keys(gateways[keyGateway]).length !== 0) {
          for (const childrenKeyGateway in gateways[keyGateway]) {
            if (
              METHODS[childrenKeyGateway] &&
              METHODS[childrenKeyGateway].isActive
            ) {
              activeMethods.push(childrenKeyGateway);
            }
          }
        }
      }
      SetMethods(activeMethods);
    }
    PrepareMethods();
  }, [gateways]);

  if (!gateways) {
    return <p>carregando</p>;
  }

  return (
    <div>
      <Row>
        <Text strong>Métodos disponíveis</Text>
      </Row>
      <Row>
        {methods.map((method) => (
          <Col key={method} xs={12} sm={12} md={6} lg={6} xl={6}>
            <MethodCard
              Icon={METHODS[method].icon}
              text={METHODS[method].text}
              name={method}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
