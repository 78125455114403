import React, { memo, useContext } from 'react';

import imageCompression from 'browser-image-compression';

import { ThemeContext } from '~/contexts/ThemeContext';

import PDFFileImage from '~/assets/images/pdf.png';

import {
  Label,
  Input,
  UploadContainer,
  Image,
  Link,
  Div,
  Delete,
  View,
} from './style';

const UploadInput = ({
  name,
  files,
  width,
  height,
  onSelect,
  onDelete,
  text,
  maximumUploads
}) => {
  const { theme } = useContext(ThemeContext);

  async function CompressFile(file) {
    await imageCompression.getExifOrientation(file);

    const options = {
      maxWidthOrHeight: 600,
      maxSizeMB: 1.5,
      useWebWorker: true,
      initialQuality: 0.7,
    };

    const output = await imageCompression(file, options);

    output.lastModifiedDate = new Date();

    const outputFile = new File([output], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });

    return outputFile;
  }

  const onChangeFile = async (files) => {
    if (files[0].type.includes('image')) {
      const file = await CompressFile(files[0]);
      return onSelect(file);
    }
    return onSelect(files[0]);
  };

  function VerifyFile(file) {
    const split = file.split('.');
    if (split[split.length - 1] === 'pdf') {
      return PDFFileImage;
    }
    return file;
  }

  return (
    <UploadContainer>
      {files.length < maximumUploads &&
        <Label
          width={width || 100}
          height={height || 100}
          borderColor={theme.color.borderColor}
          backgroundColor={theme.color.backgroundSecondary}
          hoverColor={theme.color.primary}
          htmlFor={name}
        >
          {text || 'Anexar Foto'}
        </Label>
      }

      <Input
        type="file"
        value=""
        onChange={(event) => onChangeFile(event.target.files)}
        name={name}
        id={name}
        required
      />
      {files &&
        files.map((file) => (
          <Div key={file}>
            {onDelete && (
              <Delete
                color={theme.color.white}
                hovercolor={theme.color.danger}
                onClick={() => onDelete(file)}
              />
            )}

            <Link href={file} target="_blank">
              <View color={theme.color.white} hovercolor={theme.color.yellow} />
            </Link>

            <Image src={VerifyFile(file)} size={100} />
          </Div>
        ))}
    </UploadContainer>
  );
};

export default memo(UploadInput);
