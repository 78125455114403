import React from 'react';
// Libraries
import { QrcodeOutlined } from '@ant-design/icons';
//import { BsCreditCard } from 'react-icons/bs';
import { BiUserCircle, BiTransfer } from 'react-icons/bi';
//import { ImCreditCard } from 'react-icons/im';
import { RiBarcodeFill, RiFileTransferLine } from 'react-icons/ri';
import { SiPixiv } from 'react-icons/si';
import { FaRegCreditCard } from 'react-icons/fa';
import { ImCreditCard } from 'react-icons/im';
// Methos
import BilletManualContent from './methods/BilletManual/content';
import BilletManualButton from './methods/BilletManual/button';
import DOCManualContent from './methods/DOCManual/content';
import DOCManualButton from './methods/DOCManual/button';
import PixManualContent from './methods/PixManual/content';
import PixManualButton from './methods/PixManual/button';
import SocialMeAccountCCContent from './methods/SocialMeAccountCC/content';
import SocialMeAccountCCButton from './methods/SocialMeAccountCC/button';
import SocialMeAccountCSContent from './methods/SocialMeAccountCS/content';
import SocialMeAccountCSButton from './methods/SocialMeAccountCS/button';
import SocialMeAccountCSQRCodeContent from './methods/SocialMeAccountCSQRCode/content';
import SocialMeAccountCSQRCodeButton from './methods/SocialMeAccountCSQRCode/button';
import TEDManualContent from './methods/TEDManual/content';
import TEDManualButton from './methods/TEDManual/button';
import TransferManualContent from './methods/TransferManual/content';
import TransferManualButton from './methods/TransferManual/button';
import DEBITContent from './methods/DEBIT/content';
import DEBITButton from './methods/DEBIT/button';

export default {
  'ME-CC': {
    isActive: true,
    icon: BiUserCircle,
    text: 'Conta Corrente Me',
    content: <SocialMeAccountCCContent />,
    button: <SocialMeAccountCCButton />,
  },
  'ME-CS': {
    isActive: false,
    icon: BiUserCircle,
    text: 'Conta Crédito Me',
    content: <SocialMeAccountCSContent />,
    button: <SocialMeAccountCSButton />,
  },
  'ME-CS-QRCODE': {
    isActive: true,
    icon: QrcodeOutlined,
    text: 'QRCode Conta Crédito Me',
    content: <SocialMeAccountCSQRCodeContent />,
    button: <SocialMeAccountCSQRCodeButton />,
  },
  'BILLET-MANUAL': {
    isActive: true,
    icon: RiBarcodeFill,
    text: 'Boleto',
    content: <BilletManualContent />,
    button: <BilletManualButton />,
  },
  'DOC-MANUAL': {
    isActive: true,
    icon: RiFileTransferLine,
    text: 'DOC',
    content: <DOCManualContent />,
    button: <DOCManualButton />,
  },
  'PIX-MANUAL': {
    isActive: true,
    icon: SiPixiv,
    text: 'Pix',
    content: <PixManualContent />,
    button: <PixManualButton />,
  },
  'TED-MANUAL': {
    isActive: true,
    icon: RiFileTransferLine,
    text: 'TED',
    content: <TEDManualContent />,
    button: <TEDManualButton />,
  },
  'TRANSFER-MANUAL': {
    isActive: true,
    icon: BiTransfer,
    text: 'Transferência',
    content: <TransferManualContent />,
    button: <TransferManualButton />,
  },
  DEBIT: {
    isActive: true,
    icon: FaRegCreditCard,
    text: 'Cartão de débito',
    content: <DEBITContent />,
    button: <DEBITButton />,
  },
  CREDIT: {
    isActive: true,
    icon: ImCreditCard,
    text: 'Cartão de crédito',
    content: <DEBITContent />,
    button: <DEBITButton />,
  },
};
