import React from 'react';
// Own Components
import FormCashIn from '../components/FormCashIn';
// Components
import Text from '~/components/typography/text';
import WalletAccountAuth from './../../../components/WalletAccountAuth';
import UploadFiles from './../../../components/UploadFiles';

export default function PIX() {
  return (
    <div>
      <Text strong style={{ marginBottom: '10px' }}>
        Selecione a conta onde será adicionado os Me's
      </Text>
      <WalletAccountAuth />
      <Text strong style={{ marginBottom: '10px' }}>
        Envie os comprovantes da operação
      </Text>
      <UploadFiles />
      <Text strong style={{ marginBottom: '10px' }}>
        Preencha os campos abaixo para ajudar na identificação da sua
        transferência
      </Text>
      <FormCashIn />
    </div>
  );
}
