import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Environments
import System from '~/environments/System';
// Components
import Space from '~/components/space';
import Text from '~/components/typography/text';
// Contents
import DonationProductService from './contents/DonationProductService';
import Person from './contents/Person';
import Partner from './contents/Partner';
import PartnerProductService from './contents/PartnerProductService';
import PersonManualCashIn from './contents/PersonManualCashIn';
import PersonManualCashOut from './contents/PersonManualCashOut';
import SellSingleDonation from './contents/SellSingleDonation';

export default function Informations() {
  const { intent } = useContext(GatewayContext);

  if (intent.agent === System.gateway.agents.donationProductService) {
    return <DonationProductService intent={intent} />;
  }

  if (intent.agent === System.gateway.agents.person) {
    return <Person intent={intent} />;
  }

  if (intent.agent === System.gateway.agents.partner) {
    return <Partner intent={intent} />;
  }

  if (intent.agent === System.gateway.agents.partnerProductService) {
    return <PartnerProductService intent={intent} />;
  }

  if (intent.agent === System.gateway.agents.personManualCashIn) {
    return <PersonManualCashIn intent={intent} />;
  }

  if (intent.agent === System.gateway.agents.personManualCashOut) {
    return <PersonManualCashOut intent={intent} />;
  }

  if (intent.agent === System.gateway.agents.sellSingleDonation) {
    return <SellSingleDonation intent={intent} />;
  }

  return (
    <div style={{ padding: 10 }}>
      <Space direction="vertical">
        <div>
          <Text type="secondary" fontSize="10pt">
            Forma de pagamento
          </Text>
          <Text strong fontSize="14pt">
            formOfPayment
          </Text>
        </div>
        <div>
          <Text type="secondary" fontSize="10pt">
            Tempo de processamento
          </Text>
          <Text strong fontSize="14pt"></Text>
        </div>
      </Space>
    </div>
  );
}
