import React, { useContext } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Utils
import {GetBrandIcon} from '~/utils/SocialMeAssets';

export default function Flow() {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.color.backgroundSecondary,
        height: 60,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          width: '100%',
        }}
      >
        <div>
          <img
            src={GetBrandIcon('digitalWallet')}
            alt="..."
            style={{ height: 32, marginRight: 10 }}
          />
        </div>
        <div>
          <img
            src={GetBrandIcon('socialMe')}
            alt="..."
            style={{ height: 32 }}
          />
        </div>
      </div>
    </div>
  );
}
