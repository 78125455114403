import React from 'react';
// Components
import Text from '~/components/typography/text';
import WalletAccountAuth from '../../components/WalletAccountAuth';
// Own Components
import ReadButton from './components/ReadButton';
import ProductContainer from './components/ProductContainer';
// Components
import Divider from '~/components/divider';

export default function SocialMeAccountCSQRCode() {
  return (
    <div>
      <Text strong style={{ marginBottom: '10px' }}>
        Selecione a conta onde será adicionado os Me's
      </Text>
      <WalletAccountAuth />
      <ReadButton />
      <Divider />
      <ProductContainer />
    </div>
  );
}
