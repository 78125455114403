import React, { useContext } from 'react';
// Libraries
import { Form, Row, Col } from 'antd';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import AccountNumber from '~/pages/PaymentGateway/methods/components/FormItems/AccountNumber';
import Agency from '~/pages/PaymentGateway/methods/components/FormItems/Agency';
import Bank from '~/pages/PaymentGateway/methods/components/FormItems/Bank';
import CPForCNPJ from '~/pages/PaymentGateway/methods/components/FormItems/CPForCNPJ';
import Description from '~/pages/PaymentGateway/methods/components/FormItems/Description';
import FullName from '~/pages/PaymentGateway/methods/components/FormItems/FullName';

export default function FormCashIn() {
  const { formValues, setFormValues } = useContext(GatewayContext);
  const [form] = Form.useForm();
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      size="small"
      layout="vertical"
      onValuesChange={() => setFormValues(form.getFieldsValue())}
      scrollToFirstError
      initialValues={formValues}
    >
      <Row>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{ padding: '0px 5px' }}
        >
          <FullName />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{ padding: '0px 5px' }}
        >
          <CPForCNPJ />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          style={{ padding: '0px 5px' }}
        >
          <Bank />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '0px 5px' }}
        >
          <Agency />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ padding: '0px 5px' }}
        >
          <AccountNumber />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: '0px 5px' }}
        >
          <Description />
        </Col>
      </Row>
    </Form>
  );
}
