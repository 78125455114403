import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Row } from 'antd';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Own Components
import AccountCard from './AccountCard';
// Components
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function WalletAccountAuth() {
  const { FillAccount } = useContext(WalletAccountContext);
  const [accounts, setAccounts] = useState([]);
  const [getAccount, setGetAccounts] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetWalletAccounts() {
      setGetAccounts(true);
      const params = {
        operation: ['Wallet', 'Accounts', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        if (response.body.accounts.elements.length === 1) {
          FillAccount(response.body.accounts.elements[0]);
        }
        setAccounts(response.body.accounts.elements);
        return setLoadingContent(false);
      }
      //! CHAMAR ERRO NO GATEWAY
    }
    if (!getAccount) {
      GetWalletAccounts();
    }
  }, [FillAccount, getAccount]);

  if (loadingContent) {
    return <Loading height="100px" text="Carregando contas..." />;
  }

  return (
    <div>
      <Row>
        {accounts.map((account) => (
          <AccountCard key={account.account_number} account={account} />
        ))}
      </Row>
    </div>
  );
}
