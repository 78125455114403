import React, { useContext, useEffect } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Loading from '~/components/loading';
// Standalone Components
import { Container } from './style';

export default function QRCodeSecondStep({
  QRCodeInformation,
  CloseQRCodeReader,
}) {
  const { setQRCodeInformation } = useContext(GatewayContext);
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    if (QRCodeInformation) {
      setQRCodeInformation(QRCodeInformation);
      CloseQRCodeReader();
    }
  }, [QRCodeInformation, CloseQRCodeReader, setQRCodeInformation]);
  return (
    <Container backgroundcolor={theme.color.backgroundSecondaryLight}>
      <Loading />
    </Container>
  );
}
