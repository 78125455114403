import React, { useEffect, useContext, useState } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
// Utils
import { WalletFormatConversion } from '~/utils/Transformations';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function Balance() {
  const { theme } = useContext(ThemeContext);
  const { amount, intent } = useContext(GatewayContext);

  const [balance, setBalance] = useState(0);
  const [loadingBalance, setLoadingBalance] = useState(true);

  useEffect(() => {
    async function GetBalance() {
      const params = {
        operation: ['Wallet', 'Accounts', 'VerifyProductService'],
        urlParams: {
          productServiceId: intent.psm_id,
        },
      };
      const response = await SocialMeAPI(params);
      if (response.success) {
        setBalance(response.body.amount.SME);
        return setLoadingBalance(false);
      }
      return setLoadingBalance(false);
    }
    GetBalance();
  }, [intent]);

  function VerifyBalance() {
    const orderValue = amount;
    if (balance >= orderValue) {
      return (
        <Text color={theme.color.success} textAlign="center">
          Saldo disponível para o pagamento
        </Text>
      );
    } else {
      return (
        <Text color={theme.color.danger} textAlign="center">
          Saldo indisponível para o pagamento
        </Text>
      );
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {loadingBalance ? (
        <Loading text="Carregando saldo" />
      ) : (
        <div>
          <div style={{ display: 'flex' }}>
            <Text style={{ marginRight: 5 }}>Saldo disponível:</Text>
            <Text strong>{WalletFormatConversion(balance)}</Text>
          </div>
          {VerifyBalance()}
        </div>
      )}
    </div>
  );
}
