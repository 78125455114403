import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.backgroundcolor};
  min-height: calc(100vh - 55px);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
