import React from 'react';
// Libraries
import { Result } from 'antd';
// Components
import Button from '~/components/button';

export default function PendingOperation({
  GetOperationAgain,
  operationResponse,
}) {
  return (
    <div>
      <Result
        status="warning"
        title="Operação pendente"
        subTitle={`Sua operação ainda se encontra pendente.`}
        extra={[
          <Button type="dashed" key={1} onClick={() => GetOperationAgain()}>
            Verificar situação novamente
          </Button>,
          <Button
            type="primary"
            key={2}
            onClick={() =>
              window.location.replace(
                `${operationResponse.other_parameters.origin_url}`
              )
            }
          >
            Voltar para onde estava
          </Button>,
        ]}
      />
    </div>
  );
}
