import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Title from '~/components/typography/title';
// Utils
import {
  CapitalizeString,
  WalletFormatConversion,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function ProductContainer() {
  const { QRCodeInformation } = useContext(GatewayContext);
  if (!QRCodeInformation) {
    return <></>;
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title level={3} style={{ textAlign: 'center' }}>
          {CapitalizeString(QRCodeInformation.psd_name)}
        </Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
            {`${WalletFormatConversion(QRCodeInformation.amount.SME)} Mes`}
          </Title>
          <img
            style={{ width: 24, marginLeft: 10 }}
            src={PickMeCoinIcon()}
            alt="Social Me Coin"
          />
        </div>
      </div>
    </div>
  );
}
