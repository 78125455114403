import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Text from '~/components/typography/text';

export default function MethodCard({ Icon, text, name }) {
  const { activeMethod, setActiveMethod } = useContext(GatewayContext);
  const { theme } = useContext(ThemeContext);
  return (
    <div style={{ padding: 5 }}>
      <div
        onClick={() => setActiveMethod(name)}
        style={{
          backgroundColor: activeMethod === name ? theme.color.primary : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: 80,
          borderRadius: 8,
          cursor: 'pointer',
          border: `1px solid ${theme.color.borderColor}`,
        }}
      >
        <Icon
          style={{
            fontSize: '24pt',
            color:
              activeMethod === name ? theme.color.textOnPrimaryBackground : '',
          }}
        />
        <Text
          style={{
            fontSize: '12pt',
            color:
              activeMethod === name ? theme.color.textOnPrimaryBackground : '',
            textAlign: 'center',
          }}
        >
          {text}
        </Text>
      </div>
    </div>
  );
}
