import React, { createContext, useState, useEffect, useContext } from 'react';
//Libraries
import { message } from 'antd';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import LoadingGateway from '~/components/loading/loadingGateway';
// Environments
import UrlParameters from '~/environments/UrlParameters';
import System from '~/environments/System';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetModuleNameByHostname } from '~/utils/Hosts';

export const GatewayContext = createContext();

const GatewayContextProvider = ({ children }) => {
  const { walletAccount, walletPassword } = useContext(WalletAccountContext);
  const [preparingGateway, setPreparingGateway] = useState(false);
  const [activeMethod, setActiveMethod] = useState('');
  const [intent, setIntent] = useState();
  const [gateways, setGateways] = useState();
  const [contract, setContract] = useState();
  const [amount, setAmount] = useState();
  const [anonymous, setAnonymous] = useState(false);
  const [completedOperation, setCompletedOperation] = useState();
  const [operationResponse, setOperationResponse] = useState();
  const [formValues, setFormValues] = useState();
  const [QRCodeInformation, setQRCodeInformation] = useState();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    async function GetContract(data) {
      const params = {
        operation: ['Gateway', 'GetContract'],
        urlParams: {
          intentId: intent._id,
        },
        data,
      };
      console.log(data);
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        setContract(response.body.contract);
      }
      return setPreparingGateway(false);
    }
    async function PrepareGateway() {
      for (const key in gateways) {
        if (gateways[key][activeMethod]) {
          return key;
        }
      }
    }
    async function PrepareData() {
      let data = {
        gateway: await PrepareGateway(),
        method: activeMethod,
      };
      GetContract(data);
    }
    if (intent) {
      if (activeMethod) {
        PrepareData();
      }
      return setPreparingGateway(false);
    }
  }, [activeMethod, intent, gateways]);

  async function AddIntent(data) {
    const params = {
      operation: ['Gateway', 'CreatesTheIntent'],
      data,
    };
    console.log(params);
    const response = await SocialMeAPI(params);
    console.log(response);
    if (response.success) {
      let id = response.body._id;
      let piece1 = id.substring(0, 10);
      let piece2 = id.substring(10, 21);
      let piece3 = id.substring(21, 32);
      window.location.replace(
        `https://${System.hostname.paymentGateway}/?${UrlParameters.gatewayPiece1}=${piece1}&${UrlParameters.gatewayPiece2}=${piece2}&${UrlParameters.gatewayPiece3}=${piece3}`
      );
    }
  }

  async function CreatesTheIntent({ agent, agent_id, value, parameters }) {
    console.log('Creates the intention');
    setPreparingGateway(true);
    // Origin_System
    const module = GetModuleNameByHostname();
    const origin_system = System.gateway.origins[module];
    if (!origin_system) {
      message.error('Alguma coisa aconteceu...');
      return setPreparingGateway(false);
    }
    // Agent
    if (!agent) {
      message.error('Alguma coisa aconteceu...');
      return setPreparingGateway(false);
    }
    // Value
    if (!value) {
      message.error('Alguma coisa aconteceu...');
      return setPreparingGateway(false);
    }
    let otherParameters = {};
    if (parameters) {
      otherParameters = parameters;
    }
    otherParameters.origin_url = window.location.href;
    // Data
    const data = {
      origin_system: System.gateway.origins[module],
      agent: agent,
      agent_id: agent_id,
      value: value,
      other_parameters: otherParameters,
    };
    console.log('data -> ', data);
    AddIntent(data);
  }

  async function ConfirmOperation(data) {
    setPreparingGateway(true);
    async function PrepareGateway() {
      for (const key in gateways) {
        if (gateways[key][activeMethod]) {
          return key;
        }
      }
    }
    let treatedData = {
      method: activeMethod,
      gateway: await PrepareGateway(),
      anonymous: false,
      amount: amount,
    };
    if (walletAccount) {
      treatedData.account_number = walletAccount.account_number;
      treatedData.account_vd = walletAccount.account_vd;
      treatedData.agency_number = walletAccount.agency_number;
    }
    if (walletPassword) {
      treatedData.password = walletPassword;
    }
    if (data.other_parameters) {
      treatedData.other_parameters = data.other_parameters;
    }
    if (data.settings) {
      treatedData.settings = data.settings;
    }
    if (data.payer) {
      treatedData.payer = data.payer;
      treatedData.payer.mainDocument = formValues.payer.mainDocument.replace(
        /[^\d]+/g,
        ''
      );
    }
    if (data.address) {
      treatedData.address = data.address;
    }
    if (data.card) {
      treatedData.card = data.card;
      //treatedData.card.brand = formValues.flag;
      treatedData.card.number = formValues.card.number.replace(/\s/g, '');
      let validitySplit = data.card.expirationDate.split('/');
      let validityValue = `20${validitySplit[1]}-${validitySplit[0]}-00`;
      treatedData.card.expirationDate = validityValue;
    }
    if (data.QRCode) {
      treatedData.QRCode = data.QRCode;
    }
    //console.log('treatedData -> ', treatedData);
    const params = {
      operation: ['Gateway', 'ConfirmOperation'],
      urlParams: {
        intentId: intent._id,
        contractId: contract._id,
      },
      data: treatedData,
    };
    //console.log('params -> ', params.data);
    const response = await SocialMeAPI(params);
    //console.log('response -> ', response);
    if (response.success) {
      if (response.body.operation.status === 'PENDING') {
        if (
          response.body.operation.settings &&
          response.body.operation.settings.url &&
          response.body.operation.settings.url.length > 2
        ) {
          return window.location.replace(response.body.operation.settings.url);
        }
      }
      setOperationResponse(response.body.operation);
      let operationId = response.body.operation._id;
      let piece1 = operationId.substring(0, 11);
      let piece2 = operationId.substring(11, 22);
      let piece3 = operationId.substring(22, 33);
      return window.location.replace(
        `https://${System.hostname.paymentGateway}/payment/?${UrlParameters.gatewayPiece1}=${piece1}&${UrlParameters.gatewayPiece2}=${piece2}&${UrlParameters.gatewayPiece3}=${piece3}`
      );
    } else {
      setOperationResponse({
        status: 'INACTIVE',
      });
    }
    setPreparingGateway(false);
    setCompletedOperation(true);
    return response;
  }

  return (
    <GatewayContext.Provider
      value={{
        CreatesTheIntent,
        activeMethod,
        setActiveMethod,
        intent,
        setIntent,
        gateways,
        setGateways,
        contract,
        amount,
        setAmount,
        ConfirmOperation,
        formValues,
        setFormValues,
        anonymous,
        setAnonymous,
        QRCodeInformation,
        setQRCodeInformation,
        files,
        setFiles,
        completedOperation,
        operationResponse,
      }}
    >
      {preparingGateway ? <LoadingGateway /> : children}
    </GatewayContext.Provider>
  );
};

export default GatewayContextProvider;
