import React, { useContext } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Title from '~/components/typography/title';
import Card from '~/components/card';
// Own Components
import Informations from './components/Informations';
import ValuesContainer from './components/ValuesContainer';
import ConfirmButton from './components/ConfirmButton';

export default function OrderSummary() {
  const { theme } = useContext(ThemeContext);
  return (
    <div style={{ padding: '0px 5px' }}>
      <Card
        bodyStyle={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: 10,
        }}
      >
        <Title level={4} style={{ textAlign: 'center' }}>
          Resumo do pedido
        </Title>
        <div style={{ padding: 10 }}>
          <div
            style={{
              border: `1px solid ${theme.color.borderColor}`,
              width: '100%',
              borderRadius: 8,
            }}
          >
            <Informations />
            <ValuesContainer />
          </div>

          <ConfirmButton />
        </div>
      </Card>
    </div>
  );
}
