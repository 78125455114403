import React, { useContext } from 'react';
// Libraries
import { BsFileEarmarkPlus } from 'react-icons/bs';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Space from '~/components/space';
import { UploadArray } from '~/components/uploadInput';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function UploadFiles({maximumUploads}) {
  const { files, setFiles } = useContext(GatewayContext);

  async function AddFile(file) {
    const data = new FormData();
    data.append('file', file);
    let params = {
      operation: ['Gateway', 'UploadFileToTheIntention'],
      data,
    };
    console.log('params -> ', params);
    const response = await SocialMeAPI(params);
    console.log('upload -> ', response);
    if (response.success) {
      setFiles([...files, response.body.file]);
    }
  }

  async function RemoveFile(file){
    console.log('file -> ', file);
    let filteredArray = files.filter(item => item !== file);
    setFiles(filteredArray);
  }

  return (
    <div>
      <UploadArray
        name="files"
        onSelect={AddFile}
        onDelete={RemoveFile}
        files={files}
        maximumUploads={maximumUploads}
        text={
          <Space direction="vertical" align="center" size="small">
            <BsFileEarmarkPlus style={{ fontSize: '18pt' }} />
            Anexar arquivo
          </Space>
        }
      />
    </div>
  );
}
