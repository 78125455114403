import React from 'react';
// Libraries
import { Route, Switch, Redirect } from 'react-router-dom';
// Pages
import Home from './pages/Home';
import Verify from './pages/Verify';

export default function PaymentCheck() {
  return (
    <Switch>
      <Route exact path={`/`} component={Home} />
      <Route path={`/payment`} component={Verify} />
      <Route exact path={`*`}>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
