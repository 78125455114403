import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function FormItemCPForCNPJ() {
  return (
    <FormItem
      name={['settings', 'mainDocument']}
      item={<Input label={'CPF/CNPJ'} />}
    />
  );
}
