import React, { useContext, useEffect, useState } from 'react';
// Libraries
//import { QuestionCircleOutlined } from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { GatewayContext } from '~/contexts/GatewayContext';
// Own Components
import ModalContent from './components/ModalContent';
// Components
import Text from '~/components/typography/text';
import Button from '~/components/button';
import Modal from '~/components/modal';
//import Tooltip from '~/components/tooltip';
// Environments
import System from '~/environments/System';
// Utils
import {
  applyTaxToAmount,
  WalletFormatConversion,
} from '~/utils/Transformations';

export default function Values() {
  const { theme } = useContext(ThemeContext);
  const { intent, contract, setAmount } = useContext(GatewayContext);
  const [initialValue, setInitialValue] = useState();
  const [markup, setMarkup] = useState();
  const [deal, setDeal] = useState();

  useEffect(() => {
    async function CalculateDeal(markupValue) {
      if (contract.deal_tax_payer === 'PAYER') {
        let value = await applyTaxToAmount(
          markupValue,
          contract.deal_tax_value,
          contract.deal_tax_percentage
        );
        //setAmount(value);
        return setDeal(value);
      }
      return setDeal(0);
    }
    async function CalculateMarkup(initialValue) {
      if (contract.markup_tax_payer === 'PAYER') {
        let markupValue = await applyTaxToAmount(
          initialValue,
          contract.markup_tax_value,
          contract.markup_tax_percentage
        );
        setMarkup(markupValue);
        return CalculateDeal(markupValue);
      }
      CalculateDeal(initialValue);
      return setMarkup(0);
    }
    async function CalculateInitialValue() {
      const valueInitial = intent.value;
      setInitialValue(valueInitial);
      CalculateMarkup(valueInitial);
    }
    if (intent && contract) {
      setInitialValue();
      setMarkup();
      setDeal();
      setAmount(intent.value);
      CalculateInitialValue();
    } else {
      setInitialValue(intent.value);
      setMarkup(0);
      setDeal(intent.value);
      setAmount(intent.value);
    }
  }, [intent, contract, setAmount]);

  function ShowMarkup() {
    if (markup > 0) {
      return markup - initialValue;
    }
    return markup;
  }

  function ShowDeal() {
    //console.log('initialValue : ', initialValue);
    //console.log('deal : ', deal);
    if (deal > 0) {
      if (markup > 0) {
        return deal - initialValue - (markup - initialValue);
      }
      return deal - initialValue;
    }
    return deal;
  }

  return (
    <div
      style={{
        borderTop: `1px solid ${theme.color.borderColor}`,
        backgroundColor: theme.color.backgroundSecondary,
        padding: 10,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      }}
    >
      {intent.agent !== System.gateway.agents.partnerProductService && (
        <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
          <Modal
            Trigger={
              <Button size="large" type="dashed">
                Alterar valor
              </Button>
            }
            footer={false}
            InheritedContent={ModalContent}
            InheritanceParameters={{ name: 'arthur' }}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text type="secondary" style={{ fontSize: '10pt' }}>
          Valor Inicial:
        </Text>
        <Text type="secondary" style={{ fontSize: '10pt' }}>
          {`R$ ${WalletFormatConversion(initialValue)}`}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text type="secondary" style={{ fontSize: '10pt' }}>
          Taxa Social Me:
        </Text>
        <Text type="secondary" style={{ fontSize: '10pt' }}>
          {`R$ ${WalletFormatConversion(ShowMarkup())}`}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text type="secondary" style={{ fontSize: '10pt' }}>
          Taxa do gateway:
        </Text>
        <Text type="secondary" style={{ fontSize: '10pt' }}>
          {`R$ ${WalletFormatConversion(ShowDeal())}`}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text type="secondary" style={{ fontSize: '12pt' }}>
          Total a pagar:
        </Text>
        <Text strong style={{ fontSize: '14pt' }}>
          {`R$ ${WalletFormatConversion(deal)}`}
        </Text>
      </div>
    </div>
  );
}
