import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Checkbox from '~/components/checkbox';
import IconHelper from '~/components/iconHelper';
// Environments
import System from '~/environments/System';
// Utils
import { ExistingUser } from '~/utils/UserInformation';

export default function AnonymousCheckbox() {
  const { intent, anonymous, setAnonymous } = useContext(GatewayContext);
  const agents = {
    [System.gateway.agents.donationProductService]: {
      isActive: true,
      text: 'Doar como anônimo',
      helpText:
        'A pessoa que irá receber sua doação não terá acesso ao seu nome e avatar',
    },
    [System.gateway.agents.partnerProductService]: {
      isActive: false,
    },
    [System.gateway.agents.person]: {
      isActive: false,
    },
    [System.gateway.agents.personManualCashIn]: {
      isActive: false,
    },
    [System.gateway.agents.personManualCashOut]: {
      isActive: false,
    },
    [System.gateway.agents.sellSingleDonation]: {
      isActive: false,
    },
  };

  function onChange() {
    setAnonymous(!anonymous);
  }

  if (!ExistingUser()) {
    return <></>;
  }

  return (
    <div>
      {agents[intent.agent].isActive && (
        <div
          style={{
            padding: '20px 0px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Checkbox
            text={agents[intent.agent].text}
            checked={anonymous}
            onChange={() => onChange()}
          />
          <IconHelper text={agents[intent.agent].helpText} />
        </div>
      )}
    </div>
  );
}
