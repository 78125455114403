import React, { useEffect } from 'react';
// Styled Components
import {
  Back,
  CardContainer,
  CardNumber,
  CardholderName,
  Chip,
  ChipLine,
  ChipMain,
  CVVContainer,
  CVVLabel,
  CVVValue,
  Flip,
  Front,
  Logo,
  StripBlack,
  ValidityContainer,
  ValidityText,
  ValidityDate,
} from './style';
// Assets
import VisaIcon from '~/assets/images/cards/visa.png';
import MastercardIcon from '~/assets/images/cards/mastercard.png';
import AmericanExpressIcon from '~/assets/images/cards/americanExpress.png';
import DinersClubIcon from '~/assets/images/cards/dinersClub.png';
import DiscoverIcon from '~/assets/images/cards/discover.png';
import JCBIcon from '~/assets/images/cards/jcb.png';
import EloIcon from '~/assets/images/cards/elo.png';

async function JCBValidator(number) {
  let firstFour = number.substring(0, 4);
  if (parseInt(firstFour) === 2131 || parseInt(firstFour) === 1800) {
    return true;
  }
  return false;
}
async function DiscoverValidator(number) {
  let firstTwo = number.substring(0, 2);
  if (parseInt(firstTwo) === 65) {
    return true;
  }
  let firstFour = number.substring(0, 4);
  if (parseInt(firstFour) === 6011) {
    return true;
  }
  return false;
}
async function DinersClubValidator(number) {
  let firstTwo = number.substring(0, 2);
  if (parseInt(firstTwo) === 36 || parseInt(firstTwo) === 38) {
    return true;
  }
  let firstThree = number.substring(0, 3);
  if (parseInt(firstThree) >= 300 && parseInt(firstThree) <= 305) {
    return true;
  }
  return false;
}
async function AmericanExpressValidator(number) {
  let firstTwo = number.substring(0, 2);
  if (parseInt(firstTwo) === 34 || parseInt(firstTwo) === 37) {
    return true;
  }
  return false;
}
async function MasterCardValidator(number) {
  let firstTwo = number.substring(0, 2);
  if (parseInt(firstTwo) >= 51 && parseInt(firstTwo) <= 55) {
    return true;
  }
  let firstFour = number.substring(0, 4);
  if (parseInt(firstFour) >= 2221 && parseInt(firstFour) <= 2720) {
    return true;
  }
  return false;
}
async function VisaValidator(number) {
  if (number.substring(0, 1) === '4') {
    return true;
  }
  return false;
}

export default function Card({
  number,
  validity,
  name,
  cvv,
  showBack,
  flag,
  setFlag,
}) {
  useEffect(() => {
    async function VerifyFlag() {
      const Visa = await VisaValidator(number);
      if (Visa) return setFlag('Visa');
      const MasterCard = await MasterCardValidator(number);
      if (MasterCard) return setFlag('Master');
      const AmericanExpress = await AmericanExpressValidator(number);
      if (AmericanExpress) return setFlag('Amex');
      const DinersClub = await DinersClubValidator(number);
      if (DinersClub) return setFlag('Diners');
      const Discover = await DiscoverValidator(number);
      if (Discover) return setFlag('Discover');
      const JCB = await JCBValidator(number);
      if (JCB) return setFlag('JCB');
      return setFlag('');
    }
    if (number) {
      VerifyFlag();
    }
    setFlag('');
  }, [number, setFlag]);
  const FlagContainer = () => {
    switch (flag) {
      case 'Visa':
        return <img style={{ height: 64 }} src={VisaIcon} alt="Visa" />;
      case 'Master':
        return (
          <img style={{ height: 64 }} src={MastercardIcon} alt="Mastercard" />
        );
      case 'Elo':
        return <img style={{ height: 64 }} src={EloIcon} alt="Elo" />;
      case 'Amex':
        return (
          <img
            style={{ height: 64 }}
            src={AmericanExpressIcon}
            alt="AmericanExpress"
          />
        );
      case 'Diners':
        return (
          <img style={{ height: 64 }} src={DinersClubIcon} alt="DinersClub" />
        );
      case 'Discover':
        return <img style={{ height: 64 }} src={DiscoverIcon} alt="Discover" />;
      case 'JCB':
        return <img style={{ height: 64 }} src={JCBIcon} alt="JCB" />;
      default:
        return <p></p>;
    }
  };
  function GetFlagColor() {
    switch (flag) {
      case 'Visa':
        return '#034ea1';
      case 'Master':
        return '#63646a';
      case 'Amex':
        return '#77a28f';
      case 'Diners':
        return '#62514f';
      case 'Discover':
        return '#5a1b29';
      case 'JCB':
        return '#b51c1b';
      case 'Elo':
        return '#1976d2';
      default:
        return 'rgba(0, 0, 0, 0.3)';
    }
  }
  const NumberContainer = () => {
    if (number) {
      return number.split(' ').map((groupNumber, index) => (
        <div key={index} className="section" style={{ height: 32 }}>
          {groupNumber}
        </div>
      ));
    }
    let blankNumber = '    ';
    return blankNumber.split(' ').map((groupNumber, index) => (
      <div key={index} className="section" style={{ height: 32 }}>
        {groupNumber}
      </div>
    ));
  };

  return (
    <CardContainer>
      <Flip showBack={showBack}>
        <Front flagColor={GetFlagColor()}>
          <Logo>{FlagContainer()}</Logo>
          {/* 
          <svg className="logo" width="40" height="40" viewbox="0 0 17.5 16.2">
            <path
              d="M3.2 0l5.4 5.6L14.3 0l3.2 3v9L13 16.2V7.8l-4.4 4.1L4.5 8v8.2L0 12V3l3.2-3z"
              fill="white"
            ></path>
          </svg>
          */}
          <Chip>
            <ChipLine />
            <ChipLine />
            <ChipLine />
            <ChipLine />
            <ChipMain />
          </Chip>
          <svg
            style={{ position: 'relative', top: 0, left: 100 }}
            viewBox="0 3.71 26.959 38.787"
            width="26.959"
            height="38.787"
            fill="white"
          >
            <path d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"></path>
            <path d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"></path>
            <path d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"></path>
          </svg>
          <CardNumber>
            {NumberContainer()}
            {/* 
            <div className="section">5453</div>
            <div className="section">2000</div>
            <div className="section">0000</div>
            <div className="section">0000</div>
            */}
          </CardNumber>
          <ValidityContainer>
            <ValidityText>Validade:</ValidityText>
            <ValidityDate> {validity}</ValidityDate>
          </ValidityContainer>
          <CardholderName>{name}</CardholderName>
          {/* 
          <div className="master">
            <div className="circle master-red"></div>
            <div className="circle master-yellow"></div>
          </div>
          */}
        </Front>
        <Back>
          <StripBlack />
          <CVVContainer>
            <CVVLabel>ccv</CVVLabel>
            <CVVValue>{cvv}</CVVValue>
          </CVVContainer>
        </Back>
      </Flip>
    </CardContainer>
  );
}
