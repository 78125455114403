import styled from 'styled-components';
//Environments
import System from '~/environments/System';

const responsiveBreakpoint = `${System.responsiveBreakpoint}px`;

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  @media (max-width: ${responsiveBreakpoint}) {
    width: 100%;
    padding: 10px;
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
`;
export const Card = styled.div`
  width: ${(style) => style.width || 'auto'};
  text-align: center;
  padding: 30px;
  border-radius: 8px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  @media (max-width: ${responsiveBreakpoint}) {
    border: none;
  }
`;