import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function FormItemAccountNumber() {
  return (
    <FormItem
      name={['settings', 'accountNumber']}
      item={<Input label={'Conta'} />}
    />
  );
}
