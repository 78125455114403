import React from 'react';
// Own Components
import FormCashOut from '../components/FormCashOut';
// Components
import Text from '~/components/typography/text';
import WalletAccountAuth from './../../../components/WalletAccountAuth';
import UploadFiles from './../../../components/UploadFiles';

export default function CashOut() {
  return (
    <div>
      <Text strong style={{ marginBottom: '10px' }}>
        Selecione a conta onde será retirado os Me's
      </Text>
      <WalletAccountAuth />
      <Text strong style={{ marginBottom: '10px' }}>
        Envie o(s) boleto(s)
      </Text>
      <UploadFiles maximumUploads={1} />
      <Text strong style={{ marginBottom: '10px' }}>
        Preencha os campos complementares abaixo
      </Text>
      <FormCashOut />
    </div>
  );
}
