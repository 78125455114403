import React from 'react';
// Components
import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';
// Utils
import { CapitalizeString } from '~/utils/Transformations';

export default function Person({ intent }) {
  return (
    <div style={{ padding: 10, display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 5,
        }}
      >
        <Avatar src={intent.p_person_avatar} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Text style={{ fontSize: '10pt' }} strong>
          {CapitalizeString(intent.p_person_name)}
        </Text>
        <Text
          style={{ fontSize: '8pt' }}
          kind="secondary"
        >{`${intent.p_account_number} - ${intent.p_account_vd}`}</Text>
      </div>
    </div>
  );
}
