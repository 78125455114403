import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone Components
import {
  MainInputNumber,
  Container,
  LabelContainer,
  RequiredSpan,
} from './style';

function AntInputNumber({
  min,
  max,
  defaultValue,
  onChange,
  size,
  step,
  style,
  formatter,
  parser,
  decimalSeparator,
  label,
  outFormItem,
  required,
  precision,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { translate } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  return (
    <Container style={{ position: 'relative' }}>
      <MainInputNumber
        size={size || isMobile ? 'large' : 'default'}
        min={min}
        max={max || 99999}
        step={step}
        formatter={formatter}
        parser={parser}
        decimalSeparator={decimalSeparator}
        precision={precision || 2}
        defaultValue={defaultValue}
        onChange={onChange}
        style={style}
      />
      {label && (
        <LabelContainer
          style={{
            backgroundColor: theme.color.backgroundSecondary,
            top: outFormItem ? '10px' : '-5px',
          }}
        >
          {label}
          {required && (
            <RequiredSpan color={theme.color.danger}>
              ({translate.basic.required.text})
            </RequiredSpan>
          )}
        </LabelContainer>
      )}
    </Container>
  );
}

export default memo(AntInputNumber);
