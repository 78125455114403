import React from 'react';
// Components
import Text from '~/components/typography/text';
import Avatar from '~/components/avatar';
// Utils
import { UserAvatar, UserName, ExistingUser } from '~/utils/UserInformation';

export default function UserContainer() {
    if(!ExistingUser()){
        return(
            <div></div>
        )
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: 10,
            }}
        >
            <Avatar src={UserAvatar()} style={{ marginRight: 10 }} />
            <Text>{UserName()}</Text>
        </div>
    );
}
