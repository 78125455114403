import React, { memo, useContext } from 'react';

import imageCompression from 'browser-image-compression';

import { ThemeContext } from '~/contexts/ThemeContext';

import { Label, Input } from './style';

const UploadInput = ({ name, width, height, onSelect }) => {
  const { theme } = useContext(ThemeContext);

  async function CompressFile(file) {
    await imageCompression.getExifOrientation(file);

    const options = {
      maxWidthOrHeight: 600,
      maxSizeMB: 1.5,
      useWebWorker: true,
      initialQuality: 0.7,
    };

    const output = await imageCompression(file, options);

    output.lastModifiedDate = new Date();

    const outputFile = new File([output], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });

    return outputFile;
  }

  const onChangeFile = async (files) => {
    const file = await CompressFile(files[0]);

    onSelect(file);
  };

  return (
    <>
      <Label
        width={width || 300}
        height={height || 250}
        hoverColor={theme.color.primary}
        htmlFor={name}
      >
        Anexar Foto
      </Label>
      <Input
        type="file"
        value=""
        onChange={(event) => onChangeFile(event.target.files)}
        name={name}
        id={name}
        required
      />
    </>
  );
};

export default memo(UploadInput);
