import React, { useContext, useState } from 'react';
// Libraries
import { Drawer, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import QrReader from './components/QrReader';
import Description from './components/Description';
// Components
import Text from '~/components/typography/text';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

//QR CODE CONTA CC - ARTHUR:          UU5xkZghBfwPIUGFOap
//QR CODE PESSOAL - FÁBIO:            3XTn1A8csHD7VOZuDTKviYAujDB2p
//QR CODE PRODUTO - BOLO DE CENOURA:  CWHUtLEmwzqb3+FKfHCdWZIXVup6jA

export default function QRCodeDrawer({
  Trigger,
  SecondStepComponent,
  title,
  description,
  kind,
  secondStepParameters,
  neverOpen,

  //Component,
  //qrCodeType,
  //qrCodeDescription,
  //qrCodeTitle
}) {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const [visibility, setVisibility] = useState(false);
  const [QRCodeInformation, setQRCodeInformation] = useState();
  const [QRCodeError, setQRCodeError] = useState({
    type: 'error',
    title: 'QR Code inválido',
    description:
      'Esse não parece ser um QR Code válido para a operação que deseja fazer.',
    placement: 'bottomRight',
  });
  const [QRCodeInvalid, setQRCodeInvalid] = useState();
  const [notificationCurrentlyOpen, setNotificationCurrentlyOpen] = useState(
    false
  );
  /*
  useEffect(() => {
    async function Decode() {
      const params = {
        operation: ['QRCode', 'Decode'],
        urlParams: {
          qrCodeId: 'yS7tnZKK5v8vcmwvhu7rp9dXDIBSDE',
        },
      };
      const response = await SocialMeAPI(params);
      console.log('QR Code -> ', response.body.QRCode);
      if (response.success) {
        return setQRCodeInformation(response.body.QRCode);
      }
    }
    Decode();
  }, []);
*/
  function ShowQRCodeReader() {
    if (neverOpen) {
      return setVisibility(false);
    }
    setVisibility(true);
  }

  function CloseQRCodeReader() {
    setVisibility(false);
    setQRCodeInformation();
  }

  const openNotification = () => {
    if (!notificationCurrentlyOpen) {
      notification[QRCodeError.type]({
        message: QRCodeError.title,
        description: QRCodeError.description,
        placement: QRCodeError.placement,
      });
    }
    setNotificationCurrentlyOpen(true);
    setTimeout(function () {
      setNotificationCurrentlyOpen(false);
    }, 4500);
  };

  async function GetQRCodeScanResult(scanResult) {
    //console.log(scanResult + ' - ' + QRCodeInvalid);
    if (scanResult || scanResult !== null) {
      if (scanResult === QRCodeInvalid) {
        return;
      } else {
        const params = {
          operation: ['QRCode', 'Decode'],
          urlParams: {
            qrCodeId: scanResult,
          },
        };
        const response = await SocialMeAPI(params);
        console.log('DECODE -> ', response);
        if (response.success) {
          if (kind) {
            if (kind.includes(response.body.QRCode.kind)) {
              return setQRCodeInformation(response.body.QRCode);
            }
          }
          await setQRCodeInvalid(scanResult);
          await setQRCodeError({
            ...QRCodeError,
            title: 'QR Code inválido',
            description:
              'Esse não parece ser um QR Code válido para a operação que deseja fazer',
          });
          return openNotification();
        }
        await setQRCodeInvalid(scanResult);
        await setQRCodeError({
          ...QRCodeError,
          title: 'QR Code inválido',
          description: 'Esse não parece ser um QR Code Social Me',
        });
        return openNotification();
      }
    }
  }

  async function GetQRCodeSancError(err) {
    console.error(`Error: ${err}`);
  }

  return (
    <>
      <div onClick={() => ShowQRCodeReader()}>{Trigger}</div>
      <Drawer
        width={`${isMobile ? '100vw' : '30vw'}`}
        closable={false}
        placement="right"
        visible={visibility}
        title={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ArrowLeftOutlined
              onClick={CloseQRCodeReader}
              style={{
                color: theme.color.textOnPrimaryBackground,
                cursor: 'pointer',
              }}
            />
            <Text
              style={{
                color: theme.color.textOnPrimaryBackground,
                marginLeft: 'auto',
              }}
            >
              {title}
            </Text>
          </div>
        }
        headerStyle={{
          backgroundColor: theme.color.backgroundPrimary,
          borderRadius: 0,
        }}
        bodyStyle={{
          padding: 0,
          //overflowY: 'hidden',
          maxHeight: 'calc(100vh - 55px)',
          backgroundColor: theme.color.backgroundPrimaryDark,
        }}
      >
        {QRCodeInformation ? (
          <div>
            <SecondStepComponent
              QRCodeInformation={QRCodeInformation}
              setQRCodeInformation={setQRCodeInformation}
              CloseQRCodeReader={CloseQRCodeReader}
              secondStepParameters={secondStepParameters}
            />
          </div>
        ) : (
          <div>
            <Description
              text={
                description ||
                'Use seu dispositivo ou o aplicativo do Social Me para ler o QR Code'
              }
            />
            <QrReader
              GetQRCodeScanResult={GetQRCodeScanResult}
              GetQRCodeSancError={GetQRCodeSancError}
            />
          </div>
        )}
      </Drawer>
    </>
  );
}

/* 
{QRCodeInfo.length === 0 ? (
          <>
            {QRCodeError ? (
              <Description
                icon={true}
                color={theme.color.danger}
                text={'QRCode Inválido'}
              />
            ) : (
              <Description
                color={theme.color.textOnPrimaryBackground}
                text={qrCodeDescription}
              />
            )}
            <QRCodeReader
              QRCodeType={qrCodeType}
              setQRCodeError={setQRCodeError}
              setQRCodeInformation={setQRCodeInformation}
            />
          </>
        ) : (
          <Component.mount
            CloseDrawer={CloseQRCodeReader}
            setQRCodeInformation={setQRCodeInformation}
            QRCodeInfo={QRCodeInfo}
            realoadActivitysFromAPI={Component.actions.LoadActivitysFromAPI}
          />
        )}
*/
