import React, { useContext } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Components
import Card from '~/components/card';
import Divider from '~/components/divider';
// Own Components
import MethodSelection from './components/MethodSelection';
import MethodContent from './components/MethodContent';

export default function Flow() {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Card
      style={{
        minHeight: isMobile ? 'auto' : 'calc(100vh - 70px)',
        marginBottom: isMobile ? '10px' : '0px'
      }}
    >
      <MethodSelection />
      <Divider style={{ margin: 0 }} />
      <MethodContent />
    </Card>
  );
}
