import React from 'react';
// Libraries
import { QrcodeOutlined } from '@ant-design/icons';
// Own Components
import QRCodeSecondStep from './components/QRCodeSecondStep';
// Components
import Button from '~/components/button';
import QRCodeReader from '~/components/qrcode/reader';

export default function ReadButton() {
  return (
    <div
      style={{
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <QRCodeReader
        Trigger={
          <Button icon={<QrcodeOutlined />} size="large" type="dashed">
            Ler QR Code
          </Button>
        }
        SecondStepComponent={QRCodeSecondStep}
        title={'Realizar uma venda com QR Code'}
        description={
          'Para realizar uma venda, passe sobre um QR Code SocialMe Conta Crédito Social válido'
        }
        kind={['DONATION_PRODUCT_SERVICE']}
        secondStepParameters={{}}
      />
    </div>
  );
}
