import React, { useContext, useState } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Button from '~/components/button';
import Space from '~/components/space';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
// Utils
import { WalletFormatConversion } from '~/utils/Transformations';
// Methods
import METHODS from './../../../../../methods';

export default function Checkout() {
  const { activeMethod, ConfirmOperation, amount, formValues } = useContext(
    GatewayContext
  );
  const [loadingButton, setLoadingButton] = useState(false);

  async function Confirm() {
    setLoadingButton(true);
    let data = {
      card: formValues.card,
      payer: formValues.payer,
    };
    ConfirmOperation(data);
    /*
    const response = await ConfirmOperation(data);
    console.log(response);
    if (
      response.message &&
      response.message ===
        "The parameter 'account or password' that was sent is invalid"
    ) {
      message.error('Senha incorreta');
    }
    setLoadingButton(false);
    */
  }
  return (
    <div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Revisão
      </Title>
      <Space direction="vertical">
        <Space>
          <Text strong>Método:</Text>
          <Text>{METHODS[activeMethod].text}</Text>
        </Space>
        <Space>
          <Text strong>Valor:</Text>
          <Text>{WalletFormatConversion(amount)}</Text>
        </Space>
        <Space>
          <Button
            type="primary"
            size="large"
            loading={loadingButton}
            onClick={() => Confirm()}
          >
            Confirmar operação
          </Button>
        </Space>
      </Space>
    </div>
  );
}
