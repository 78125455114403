import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function FormItemFullName() {
  return (
    <FormItem
      name={['settings', 'fullName']}
      item={<Input label={'Nome Completo'} />}
    />
  );
}
