import React from 'react';
import { Row, Col } from 'antd';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function PersonForm() {
  return (
    <div>
      <Row>
        <Col span={8} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Primeiro nome"
            name={['payer', 'firstName']}
            item={<Input />}
          />
        </Col>
        <Col span={8} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Último nome"
            name={['payer', 'lastName']}
            item={<Input />}
          />
        </Col>
        <Col span={8} style={{ padding: '0px 5px' }}>
          <FormItem
            label="CPF"
            name={['payer', 'mainDocument']}
            item={<Input mask="111.111.111-11" />}
          />
        </Col>
        <Col span={8} style={{ padding: '0px 5px' }}>
          <FormItem
            label="Telefone"
            name={['payer', 'phone']}
            item={<Input mask="11 11111-1111" />}
          />
        </Col>
        <Col span={8} style={{ padding: '0px 5px' }}>
          <FormItem label="Email" name={['payer', 'email']} item={<Input />} />
        </Col>
      </Row>
    </div>
  );
}
