import React, { useEffect, useState } from 'react';
// Libraries
import { Result } from 'antd';
// Components
import Button from '~/components/button';

export default function PendingOperation({ operationResponse, intent }) {
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    if (seconds !== 0) {
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      window.location.replace(intent.other_parameters.origin_url);
    }
  }, [seconds, intent]);

  return (
    <div>
      <Result
        status="warning"
        title="Operação pendente"
        subTitle={`Você será redirecionado em ${seconds} segundos`}
        extra={[
          <Button
            type="primary"
            key={1}
            onClick={() =>
              window.location.replace(intent.other_parameters.origin_url)
            }
          >
            Voltar de onde eu vim
          </Button>,
        ]}
      />
    </div>
  );
}
