import React, { useContext, useState } from 'react';
// Libraries
import { Form, Row, Col } from 'antd';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Standalone Components
import { Container, Content, Card } from './style';
//Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Components
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';
import Button from '~/components/button';

export default function UserAuthentication() {
  const { PopulateUser } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [usingTheAPI, setUsingTheAPI] = useState(false);

  async function Login(data) {
    setUsingTheAPI(true);
    const params = {
      operation: ['Auth', 'Login'],
      data,
    };
    const response = await SocialMeAPI(params);
    if (response.success) {
      if (response.message === 'Login successfully') {
        return PopulateUser(response.body.person, response.body.usrtkn);
      }
    } else {
      if (response.message === 'Invalid login') {
        return setUsingTheAPI(false);
      }
      if (response.message === 'Incorrect parameter sent') {
        return setUsingTheAPI(false);
      }
    }
    //console.log(response);
  }

  async function onFinish(data) {
    data.login = data.login.replace(/[^\d]+/g, '');
    data.keep = true;
    Login(data);
  }

  return (
    <>
      <Container>
        <Content>
          <Card
            style={{
              width: 350,
            }}
          >
            <Form
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
              }}
              onFinish={onFinish}
              form={form}
              layout="vertical"
              size="small"
              scrollToFirstError
            >
              <Row>
                <Col span={24}>
                  <FormItem
                    label={'CPF ou CNPJ'}
                    name={['login']}
                    item={<Input />}
                    style={{ margin: 0 }}
                  />
                </Col>
                <Col span={24}>
                  <FormItem
                    label={'Senha'}
                    name={['password']}
                    item={<Input type="password" />}
                    style={{ margin: 0 }}
                  />
                </Col>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: 20
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    loading={usingTheAPI}
                  >
                    Entrar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
      </Container>
    </>
  );
}
