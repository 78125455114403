import React from 'react';
// Components
import FormItem from '~/components/form/FormItem';
import Select from '~/components/select';
// Environments
import { TypesOfPixKeys } from '~/environments/Selects';

export default function PixKeyType() {
  return (
    <FormItem
      name={['settings', 'pixKeyType']}
      item={<Select label={'Tipo de chave do Pix'} options={TypesOfPixKeys} noDefaultValue />}
    />
  );
}
