import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Loading from '~/components/loading';
// Environments
import System from '~/environments/System';
// Contents
import CashIn from './CashIn';
import CashOut from './CashOut';

export default function PIX() {
  const { intent } = useContext(GatewayContext);

  if (!intent) {
    return <Loading />;
  }

  if (intent.agent === System.gateway.agents.personManualCashIn)
    return <CashIn />;
  if (intent.agent === System.gateway.agents.personManualCashOut)
    return <CashOut />;
}
