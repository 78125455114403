import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Own Components
import AnonymousCheckbox from './components/AnonymousCheckbox';
// Methos
import METHODS from '~/pages/PaymentGateway/methods';

export default function ConfirmButton() {
  const { activeMethod } = useContext(GatewayContext);
  return (
    <div>
      {activeMethod && METHODS[activeMethod].button}
      <AnonymousCheckbox />
    </div>
  );
}
