import React, { useContext } from 'react';
// Libraries
import Animate, { FadeIn } from 'animate-css-styled-components';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Text from '~/components/typography/text';
// Methods
import METHODS from '~/pages/PaymentGateway/methods';

export default function MethodContent() {
  const { activeMethod } = useContext(GatewayContext);

  return (
    <div>
      <Animate Animation={FadeIn} duration={['0.5s']} delay={['0s']}>
        {activeMethod && (
          <Text strong style={{ marginBottom: '10px' }}>
            Método selecionado: {METHODS[activeMethod].text}
          </Text>
        )}
        {activeMethod && METHODS[activeMethod].content}
      </Animate>
    </div>
  );
}
