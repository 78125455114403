import styled from 'styled-components';

export const Label = styled.label`
  box-sizing: border-box !important;
  background-image: url(${(props) => props.preview});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fafafa;
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-right: 10px;
  padding: 12px 4px;
  min-width: ${(props) => `${props.width}px`};
  min-height: ${(props) => `${props.height}px`};

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px dashed #d9d9d9;

  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.hoverColor};
    color: ${(props) => props.hoverColor};
  }

  @media (max-width: 768px) {
    padding: 5px;

    margin: 0;
  }
`;

export const Input = styled.input`
  display: none !important;
`;

export const Column = styled.input`
  display: flex;
  flex-direction: column;
`;
